
import React, {useState} from "react"

import Layout from "../../layout"
import WebappsForm from "../../webapps/form"

const formatTools = require("../../../../lib/formatTools");
const negosyonowAPI = require("../../../../lib/requestNegosyownow")
const webappsAPI = require("../../../../lib/requestWebapps");

const ENTITYID="nnpickup"

const defaultFormFields = [
	{"label":"Hotline", "field": "nnpickup_servicenumber", "value": "", "input": "textbox", "mode": "normal", "info":"11-digit (09nnXXXXXXX); Sent to customer order who wants assistance"},
	{"label":"Active", "field": "nnpickup_active", "value": "", "input": "checkbox", "mode": "normal"},
	{"label":"Floor / Unit / Building", "field": "nnpickup_bldgaddress", "value": "", "input": "textbox", "mode": "normal"},
	{"label":"Street Address", "field": "nnpickup_strtaddress", "value": "", "input": "textarea", "mode": "required"},
	{"label":"Municipality / City", "field": "nnpickup_city", "value": "", "input": "textbox", "mode": "required"},
	{"label":"Province/State", "field": "nnpickup_province", "value": "", "input": "textbox", "mode": "required"},
	{"label":"Location Notes", "field": "nnpickup_notes", "value": "", "input": "textarea", "mode": "normal", "info":"e.g. position from nearby landmark"},
];


const searchFields = [
	{"label":"Name", "dbfield": "nnpickup_name", "type": "text", "filtertype": "text"},
	{"label":"Type", "dbfield": "onsorderformfulfillment_name", "type": "text", "filtertype": "text"},
	{"label":"Active", "dbfield": "nnpickup_active", "type": "checkbox", "filtertype": "checkbox"},
	{"label":"Hotline", "dbfield": "nnpickup_servicenumber", "type": "text", "filtertype": "text"},
	{"label":"Floor / Unit / Building", "dbfield": "nnpickup_bldgaddress", "type": "text", "filtertype": "text"},
	{"label":"Street Address", "dbfield": "nnpickup_strtaddress", "type": "text", "filtertype": "text"},
	{"label":"Municipality / City", "dbfield": "nnpickup_city", "type": "text", "filtertype": "text"},
];


const subformFields = [
	{
		"subformid": "nnpickupemail",
		"label": "Notification Email",
		"table": "nnpickupemail",
		"sort": "nnpickupemail_info",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 999,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "email",
				"field": "nnpickupemail_info",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "required"
			},
			{
				"label": "Active",
				"field": "nnpickupemail_active",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "required"
			},
			{
				"label": "Notes/Custodian",
				"field": "nnpickupemail_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			}
		],
		"footerlist": [
		]
	},
	{
		"subformid": "nnpickupmobile",
		"label": "Notification SMS",
		"table": "nnpickupmobile",
		"sort": "nnpickupmobile_info",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 999,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "11-digit # (09nnXXXXXXX)",
				"field": "nnpickupmobile_info",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "required"
			},
			{
				"label": "Active",
				"field": "nnpickupmobile_active",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "required"
			},
			{
				"label": "Notes/Custodian",
				"field": "nnpickupmobile_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			}
		],
		"footerlist": [
		]
	}
];

const PickuppointForm = ({location, allowadd, allowdelete, custombasicformfields}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	const [onsorderformfulfillmentid, setOnsorderformfulfillmentid] = useState(0);

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		var pkid = 0;
		if (params.hasOwnProperty("documentid")) {
			if (params.documentid) {
				pkid = params.documentid;
			}
		}
		if (pkid === 0) {
			params.nnpickup_primarymobile = "";
			// Last 4 digits should be incremental for pick up and bus shipping
			if (params.onsorderformfulfillment_id+"" === "4") {
				// bus shipping
				params.nnpickup_primarymobile = "0918";
			} else if (params.onsorderformfulfillment_id+"" === "3") {
				// pick up
				params.nnpickup_primarymobile = "0917";
			}

			if (params.nnpickup_primarymobile.length > 0) {
				// Get highest in the same prefix
				webappsAPI.loadData("nnpickup", {
					"dbfieldlist":["nnpickup_primarymobile"],
					"filters":[[{
						field: "nnpickup_primarymobile",
						operation: "like",
						value: params.nnpickup_primarymobile+"%"
					}]]
				}, token).then(dataresponse => {


					if (dataresponse.status === "OK") {
						var idvalbase = 1230000;
						var curmax = "";
						var idx = 0;
						while (idx < dataresponse.data.length) {
							if (curmax < dataresponse.data[idx].nnpickup_primarymobile) {
								curmax = dataresponse.data[idx].nnpickup_primarymobile;
							}
							idx++;
						}
						if (curmax.length > 0) {
							idvalbase = parseInt(curmax.substring(4));
						}
						params.nnpickup_primarymobile = params.nnpickup_primarymobile+(idvalbase+1);

						webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
							if (response.status === "OK") {
								if (onsorderformfulfillmentid === 3) {
									negosyonowAPI.buildEntityId(response.documentid,token, "pickup").then(nnresponse => {
										callback(response);
									});
									return;
								}
							}
							callback(response);
						});
						return;
					}
					callback({"status":"Error", "message":"Unable to load pick up data"});
				});
				return;
			} // needs to load primary mobile
		} // if new document

		// Fallthrough Existing document/No series information
		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
			if (response.status === "OK") {
				if (onsorderformfulfillmentid === 3) {
					negosyonowAPI.buildEntityId(response.documentid,token, "pickup").then(nnresponse => {
						callback(response);
					});
					return;
				}
			}
			callback(response);
		});

		//callback({"status":"Error"});
	}


	function subformValidateContactValues(allsubformlist, tablename)
	{
		const subformidx = findSubformIdxByTablename(tablename);
		if (subformidx < 0) {
			return {"status": "OK"};
		}
		var itemsubform = allsubformlist[subformidx];
		var rowidx = 0;
		var colidx = 0;
		while (rowidx < itemsubform.length) {
			colidx = 0;
			while (colidx < itemsubform[rowidx].length) {
				if (itemsubform[rowidx][colidx].field === tablename+"_info") {
					if (tablename === "nnpickupmobile") {
						if (checkMobile(itemsubform[rowidx][colidx].value) === false) {
							return {"status":"Error", "message":"Invalid Mobile # "+itemsubform[rowidx][colidx].value};
						}
					} else {
						if (formatTools.validateEmail(itemsubform[rowidx][colidx].value) === false) {
							return {"status":"Error", "message":"Invalid email "+itemsubform[rowidx][colidx].value};
						}
					}
				}
				colidx++;
			}
			rowidx++;
		}
		return {"status":"OK"};
	}

	function findSubformIdxByTablename(tablename)
	{
		var subformidx = 0;
		while (subformidx < subformFields.length) {
			if (subformFields[subformidx].table === tablename) {
				return subformidx;
			}
			subformidx++;
		}
		return -1;
	}

	function checkMobile(str)
	{
		if (str.match(/^[0]\d{10}$/)) {
			return true;
		}
		return false;
	}

	function customValidateValues(mainform, subformlist, footerformlist)
	{
		// Todo: Validate mobile numbers and email
		var tmpstr = "";
		var idx = 0;
		while (idx < mainform.length) {
			if (mainform[idx].field === "nnpickup_servicenumber") {
				if (mainform[idx].value.length > 0) {
					if (checkMobile(mainform[idx].value) === false) {
						return {"status":"Error", "message":"Invalid "+mainform[idx].label+" "+tmpstr};
					}
				}
			} else if (mainform[idx].field === "onsorderformfulfillment_id") {
				setOnsorderformfulfillmentid(parseInt(mainform[idx].value, 10));
			}
			idx++;
		}
		var tmpres = subformValidateContactValues(subformlist, "nnpickupemail");
		if (tmpres.status !== "OK") {
			return tmpres;
		}

		tmpres = subformValidateContactValues(subformlist, "nnpickupmobile");
		if (tmpres.status !== "OK") {
			return tmpres;
		}

		return {"status":"OK"};
	}

	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappsForm
				location={location}
				pagetitle={pagetitle}
				allowadd={allowadd}
				allowdelete={allowdelete}
				mobilerowtitlefield={["nnpickup_name"]}
				searchFields={searchFields}
				editFields={[custombasicformfields.concat(defaultFormFields)]}
				editSubFormFields={subformFields}
				customSubmit={customSubmit}
				customValidateValues={customValidateValues}
				userSearchFilter={[{"field":"nnpickup_id", "operation":"<>", "value": 7}]}
				entity={ENTITYID}
				token={token} />
		</Layout>

}


export default PickuppointForm;
